export function maping(array) {
  const map = {};

  array.forEach((i) => {
    map[i.value] = i.label;
  });

  return map;
}

export function checkId(id) {
  var tab = "ABCDEFGHJKLMNPQRSTUVXYWZIO";
  var A1 = new Array(
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    1,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    2,
    3,
    3,
    3,
    3,
    3,
    3
  );
  var A2 = new Array(
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    0,
    1,
    2,
    3,
    4,
    5
  );
  var Mx = new Array(9, 8, 7, 6, 5, 4, 3, 2, 1, 1);
  if (!id || id.length != 10) return false;
  var i = tab.indexOf(id.charAt(0));
  if (i == -1) return false;
  var sum = A1[i] + A2[i] * 9;

  for (i = 1; i < 10; i++) {
    var v = parseInt(id.charAt(i));
    if (isNaN(v)) return false;
    sum = sum + v * Mx[i];
  }
  if (sum % 10 != 0) return false;
  return true;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 B";

  const k = 1024; // 每單位 1024 bytes
  const dm = decimals < 0 ? 0 : decimals; // 小數點位數
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k)); // 計算單位

  // 格式化數值和單位
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function generateID() {
  return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

export const uploadStatusEnum = {
  LOADING: 0, // 尚未辨識
  SUCCESS: 1, // 辨識成功
  FAILD: 2, // 辨識異常
};

export const uploadMethodsEnum = {
  PDF: 0,
  PHOTO_FROM_ALBUM: 1,
  PHOTO_FROM_CAMERA: 2,
};

export const uploadMethodsConfig = {
  [uploadMethodsEnum.PDF]: {
    path: "",
    uploadButtonText: "新增檔案",
  },
  [uploadMethodsEnum.PHOTO_FROM_ALBUM]: {
    path: "",
    uploadButtonText: "新增照片",
  },
  [uploadMethodsEnum.PHOTO_FROM_CAMERA]: {
    path: "",
    uploadButtonText: "再拍一張",
  },
};
