import axios from "axios";
import store from "@/store";
import router from "@/router";
import hmacSHA1 from "crypto-js/hmac-sha1";
import Base64 from "crypto-js/enc-base64";
//import { Toast } from "vant";
import Qs from "qs";

const service = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? "/" : "/",
  //timeout: 15000,
  responseType: "json",
  headers: {
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    //"Content-Type": "application/x-www-form-urlencoded",
  },
  withCredentials: false, // 是否攜帶cookie
  // transformRequest: [
  //   function (data) {
  //     data = Qs.stringify(data);
  //     return data;
  //   },
  // ],
});

service.interceptors.request.use(
  (config) => {
    store.commit("SHOW_LOADING");
    if (store.getters.user?.access_token) {
      config.data["access_token"] = store.getters.user.access_token;
    }
    let apiSecret = "MDE5MzkzNWM1MDFjZmM1ODk5MTAzYjI0ODcwYzAwNTQ4YzI4YTk3ZQ";
    let timestamp = Date.now();
    let plainText = `${config.data["process_code"]}_${timestamp}`;
    let verify = Base64.stringify(hmacSHA1(plainText, apiSecret));

    config.data["api_key"] =
      "MjQyYmVkNGU3M2U4ODQ5NWZhMTlhYTI3ODBlZWVlNDk3ZGVlN2M0YQ";
    config.data["verify"] = verify;
    config.data["timestamp"] = timestamp;

    return config;
  },
  (error) => {
    store.commit("HIDE_LOADING");
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    store.commit("HIDE_LOADING");

    if (response.config.error === "custom") {
      return response.data;
    } else {
      if (response.data.error) {
        switch (response.data.error.code) {
          default:
            alert(response.data.error.message);
            break;
        }
        return false;
      } else {
        return response.data;
      }
    }
  },
  (error) => {
    //Toast.fail("系統錯誤，請稍後再試");
    alert("系統錯誤，請稍後再試");
    store.commit("HIDE_LOADING");
    return Promise.reject(error);
  }
);

export default service;
