import {
  GetAnalyzeReportList,
  GetHealthReportImageList,
  UploadFile,
} from "@/api/api";
import { uploadStatusEnum } from "@/utils/utility.js";

function initialState() {
  return {
    image_list: null,
    report_uuid: null,
    report_result: null,
    all_file_list: null,
  };
}
export default {
  state: initialState,
  mutations: {
    UPDATE_IMAGE_LIST: (state, value) => {
      const items = Array.isArray(value)
        ? value
        : [{ check_status: uploadStatusEnum.LOADING, ...value }];

      // 合併相同 uuid/id 的項目,未上傳到server的圖片只有id,已上傳到server後會產生uuid
      const mergedItems = items.map((newItem) => {
        const existingItem = (state.image_list || []).find(
          (item) => item.uuid === newItem.uuid || item.id === newItem.id
        );
        return existingItem ? { ...existingItem, ...newItem } : newItem;
      });

      // 篩選出沒有在 mergedItems 中的項目，將 mergedItems 合併
      state.image_list = [
        // 找到已經存在的項目，不在 mergedItems 中的項目會保留
        ...(state.image_list || []).filter(
          (item) =>
            !mergedItems.some(
              (newItem) => newItem.uuid === item.uuid || newItem.id === item.id
            )
        ),
        // 更新的項目
        ...mergedItems,
      ];
    },
    REMOVE_IMAGE_LIST_ITEM: (state, value) => {
      state.image_list = state.image_list.filter((foo) => foo !== value);
    },
    SET_REPORT_UUID: (state, value) => {
      state.report_uuid = value;
    },
    UPDATE_REPORT_RESULT: (state, value) => {
      state.report_result = value;
    },
    UPDATE_REPORT_FILE_LIST: (state, value) => {
      state.all_file_list = value;
    },
    RESET_HEALTH_REPORT: (state) => {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    removeImageListItem({ commit }, value) {
      commit("REMOVE_IMAGE_LIST_ITEM", value);
    },
    updateImageList({ commit }, value) {
      commit("UPDATE_IMAGE_LIST", value);
    },
    async handleUploadFile({ dispatch, state }, { file, id }) {
      const res = await UploadFile(file);

      if (res) {
        let defaultItem = state.image_list.find((item) => item.id === id);

        if (defaultItem) {
          dispatch("updateImageList", { ...defaultItem, ...res });
        }
      }
    },
    setReportId({ commit }, value) {
      commit("SET_REPORT_UUID", value);
    },
    updateReportResult({ commit }, value) {
      commit("UPDATE_REPORT_RESULT", value);
    },
    fetchReportFileList({ commit }, reportId) {
      const list = [];

      GetHealthReportImageList({ report_uuid: reportId })
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            // 組image file_name, ex: image{index}.png
            res.data.forEach((item, index) => {
              item.name = `image${index + 1}.${item.file_type}`;
            });

            list.push(...res.data);
          }
        })
        .finally(() => {
          commit("UPDATE_REPORT_FILE_LIST", list);
        });
    },
    resetHealthReport({ commit }) {
      commit("RESET_HEALTH_REPORT");
    },
  },
  getters: {
    image_list: (state) => state.image_list,
    report_uuid: (state) => state.report_uuid,
    report_result: (state) => state.report_result,
    report_options: (state) =>
      state.report_result
        ? state.report_result.template.map((option) => ({
            item_name: option.item_name,
            item_id: option.item_id,
            unit_standard: option.unit_standard,
          }))
        : [],
    all_file_list: (state) => state.all_file_list,
  },
};
