import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//css
import "./assets/css/main.scss";
import "vant/lib/index.less";

//utils
import { maping } from "./utils/utility";

//plugin
import ElementUI from "element-ui";
Vue.use(ElementUI);
import {
  Popup,
  Form,
  Field,
  Calendar,
  DatetimePicker,
  CellGroup,
  Picker,
} from "vant";
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Calendar);
Vue.use(DatetimePicker);
Vue.use(CellGroup);
Vue.use(Picker);

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
Vue.prototype.$dayjs = dayjs;
Vue.prototype.$maping = maping;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
