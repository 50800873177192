import request from "./request";

//判斷是否註冊
export function CHECKREGISTER(data) {
  data.process_code = "checkRegister";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//登入
export function LOGIN(data) {
  data.process_code = "login";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//發送驗證碼
export function SENDSMSVERIFY(data) {
  data.process_code = "sendSMSVerify";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//驗證手機
export function PHONEVERIFY(data) {
  data.process_code = "phoneVerify";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//完成註冊
export function REGISTER(data) {
  data.process_code = "register";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//取得user email
export function GETEMAIL(data) {
  data.process_code = "getEmail";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//忘記密碼send email取得驗證碼
export function SENDEMAIL(data) {
  data.process_code = "forgetPassword";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//重設密碼
export function RESETPASSWORD(data) {
  data.process_code = "resetPassword";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//設定名稱
export function SETNAME(data) {
  data.process_code = "setName";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//設定基本資料
export function SETPROFILE(data) {
  data.process_code = "setProfile";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//註冊後取得授權資訊
export function GETDATAAUTHORIZE(data) {
  data.process_code = "getDataAuthorize";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//取得基本資料
export function GETPROFILE(data) {
  data.process_code = "getProfile";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//取得可兌換商品
export function GETGOODS(data) {
  data.process_code = "getGoods";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//更新密碼
export function UPDATEPASSWORD(data) {
  data.process_code = "updatePassword";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//檢查user個人資料
export function CHECKPERSONALPROFILE(data) {
  data.process_code = "checkPersonalProfile";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
    error: "custom",
  });
}

//上傳圖片
// export function uploadImage(file) {
//   return request({
//     method: "post",
//     url: "/api/school/uploadImage",
//     headers: {
//       "Content-Type": `multipart/form-data`,
//     },
//     data: file,
//     transformRequest: (data) => data,
//   });
// }

// 健檢報告
//上傳檔案
export function UploadFile(data) {
  return request({
    method: "post",
    url: "/api/web/uploadFile",
    headers: {
      "Content-Type": `multipart/form-data`,
    },
    data,
    transformRequest: (data) => data,
  });
}

//取得檔案圖片辨識狀態
export function GetImageProcessStatus(data) {
  data.process_code = "getImageProcessStatus";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//取得個人分析報告列表
export function GetAnalyzeReportList(data) {
  data.process_code = "getAnalyzeReportList";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//取得健檢報告任務列表
export function GetHealthReportList(data) {
  data.process_code = "getHealthReportList";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//建立新的健檢報告任務
export function CreateHealthReport(data) {
  data.process_code = "createHealthReport";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//新增報告圖片、檔案編號
export function UpdateHealthReportImage(data) {
  data.process_code = "updateHealthReportImage";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//移除報告圖片、檔案編號
export function RemoveHealthReportImage(data) {
  data.process_code = "removeHealthReportImage";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//檢視報告圖片、檔案清單
export function GetHealthReportImageList(data) {
  data.process_code = "getHealthReportImageList";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//變更健檢報告顯示名稱、檢測日期
export function UpdateHealthReport(data) {
  data.process_code = "updateHealthReport";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//取得檔案圖片辨識結果
export function GetHealthReportResult(data) {
  data.process_code = "getHealthReportResult";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
  });
}

//更新檔案圖片辨識結果
export function UpdateHealthReportResult(data) {
  data.process_code = "updateHealthReportResult";
  return request({
    method: "post",
    url: "/api/v1/process",
    data: data,
    error: "custom",
  });
}
